import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const ReceiptPOCReport = () => {
  const [receiptNo, setReceiptNo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [from, setFrom] = useState(moment(new Date()).format('YYYY-MM'));
  const [to, setTo] = useState(moment(new Date()).format('YYYY-MM'));
  const [pdfData, setPdfData] = useState({
    from: '',
    to: '',
    tableTitles: [],
    tableContents: [],
    mmfTotal: {},
    cottonTotal: {},
  });
  const filterColumns: Array<String> = [
    'item_code',
    'item_name',
    'UOM',
    'last_pur_date',
    'Rate_per_qty',
    'Purchase_qty',
    'Consumption_Qty',
    'closing_stock',
    'opening_stock',
    'total_stock',
    'consumption_value',
    'closing_value',
  ];
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const columns = [
    {
      name: 'Item Code',
      selector: (row) => `${row.item_code}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Item Name',
      selector: (row) => `${row.item_name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'UOM',
      selector: (row) => `${row.UOM}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Last Purchase Date',
      selector: (row) => `${row.last_pur_date}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Rate Per Qty',
      selector: (row) => `${row.Rate_per_qty}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Purchase Qty',
      selector: (row) => `${row.Purchase_qty}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Opening Stock',
      selector: (row) => `${row.opening_stock}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Total Stock',
      selector: (row) => `${row.total_stock}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Consumption Qty',
      selector: (row) => `${row.Consumption_Qty}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Consumption Value',
      selector: (row) => `${row.consumption_value}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Closing Value',
      selector: (row) => `${row.closing_value}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Closing Stock',
      selector: (row) => `${row.closing_stock}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const pdfTableTitles = [
    'Item Code',
    'Item Name',
    'UOM',
    'Last Pur Date',
    'Rate per Qty',
    'Opening Stock',
    'Purchase qty',
    'Total Stock',
    'Consumption Qty',
    'Consumption Val',
    'Closing stk',
    'Closing Value',
  ];
  const getLocaleNumber = (num: any, fraction = 2) => {
    num = +num;
    return num.toLocaleString('en-IN', { maximumFractionDigits: fraction, minimumFractionDigits: fraction });
  };
  const getApi = async () => {
    showLoader();
    let res = await axios.get(
      `${baseUri()}grey-receipt-advice/store-item-bank-report?from=${to}-01&to=${to}-${new Date(
        to.split('-')[0],
        to.split('-')[1],
        0,
      ).getDate()}`,
    );
    res = (await res.data.data) || [];
    const pdfTableContents = [];
    const totalContent = {
      Rate_per_qty: 0,
      Purchase_qty: 0,
      Consumption_Qty: 0,
      closing_stock: 0,
      opening_stock: 0,
      total_stock: 0,
      consumption_value: 0,
      closing_value: 0,
    };
    const grpTotal = {
      Rate_per_qty: 0,
      Purchase_qty: 0,
      Consumption_Qty: 0,
      closing_stock: 0,
      opening_stock: 0,
      total_stock: 0,
      consumption_value: 0,
      closing_value: 0,
    };
    let currItemCode = '';
    res.forEach(
      (
        row: {
          item_code: any;
          item_name: any;
          UOM: any;
          last_pur_date: any;
          Rate_per_qty: any;
          Purchase_qty: any;
          Consumption_Qty: any;
          closing_stock: any;
          opening_stock: any;
          total_stock: any;
          consumption_value: any;
          closing_value: any;
        },
        idx: any,
      ) => {
        row.opening_stock = +row.closing_stock - +row.Purchase_qty + +row.Consumption_Qty;
        row.total_stock = +row.opening_stock + +row.Purchase_qty;
        row.consumption_value = +row.Consumption_Qty * +row.Rate_per_qty;
        row.closing_value = +row.closing_stock * +row.Rate_per_qty;
        row.opening_stock = row.opening_stock < 0 ? 0 : +row.opening_stock;
        if (currItemCode !== '' && +currItemCode !== +row.item_code.substring(0, 2)) {
          pdfTableContents.push([
            '',
            '',
            '',
            '',
            getLocaleNumber(grpTotal.Rate_per_qty),
            getLocaleNumber(grpTotal.opening_stock),
            getLocaleNumber(grpTotal.Purchase_qty, 3),
            getLocaleNumber(grpTotal.total_stock),
            getLocaleNumber(grpTotal.Consumption_Qty, 3),
            getLocaleNumber(grpTotal.consumption_value),
            getLocaleNumber(grpTotal.closing_stock),
            getLocaleNumber(grpTotal.closing_value),
          ]);
          grpTotal.Rate_per_qty = 0;
          grpTotal.Purchase_qty = 0;
          grpTotal.Consumption_Qty = 0;
          grpTotal.closing_stock = 0;
          grpTotal.opening_stock = 0;
          grpTotal.total_stock = 0;
          grpTotal.consumption_value = 0;
          grpTotal.closing_value = 0;
          currItemCode = '';
        }
        if (+row.Purchase_qty !== 0 || +row.Consumption_Qty !== 0 || +row.closing_stock !== 0) {
          pdfTableContents.push([
            row.item_code,
            row.item_name,
            row.UOM,
            row.last_pur_date ? moment(row.last_pur_date).format('D/M/YY') : '',
            getLocaleNumber(row.Rate_per_qty),
            getLocaleNumber(row.opening_stock),
            getLocaleNumber(row.Purchase_qty, 3),
            getLocaleNumber(row.total_stock),
            getLocaleNumber(row.Consumption_Qty, 3),
            getLocaleNumber(row.consumption_value),
            getLocaleNumber(row.closing_stock),
            getLocaleNumber(row.closing_value),
          ]);
        }
        currItemCode = row.item_code.substring(0, 2);
        grpTotal.Rate_per_qty += +row.Rate_per_qty;
        grpTotal.Purchase_qty += +row.Purchase_qty;
        grpTotal.Consumption_Qty += +row.Consumption_Qty;
        grpTotal.closing_stock += +row.closing_stock;
        grpTotal.opening_stock += +row.opening_stock;
        grpTotal.total_stock += +row.total_stock;
        grpTotal.consumption_value += +row.consumption_value;
        grpTotal.closing_value += +row.closing_value;
        totalContent.Rate_per_qty += +row.Rate_per_qty;
        totalContent.Purchase_qty += +row.Purchase_qty;
        totalContent.Consumption_Qty += +row.Consumption_Qty;
        totalContent.closing_stock += +row.closing_stock;
        totalContent.opening_stock += +row.opening_stock;
        totalContent.total_stock += +row.total_stock;
        totalContent.consumption_value += +row.consumption_value;
        totalContent.closing_value += +row.closing_value;
      },
    );
    pdfTableContents.push(
      [
        '',
        '',
        '',
        '',
        getLocaleNumber(grpTotal.Rate_per_qty),
        getLocaleNumber(grpTotal.opening_stock),
        getLocaleNumber(grpTotal.Purchase_qty, 3),
        getLocaleNumber(grpTotal.total_stock),
        getLocaleNumber(grpTotal.Consumption_Qty, 3),
        getLocaleNumber(grpTotal.consumption_value),
        getLocaleNumber(grpTotal.closing_stock),
        getLocaleNumber(grpTotal.closing_value),
      ],
      [
        'Grand Total',
        '',
        '',
        '',
        getLocaleNumber(totalContent.Rate_per_qty),
        getLocaleNumber(totalContent.opening_stock),
        getLocaleNumber(totalContent.Purchase_qty, 3),
        getLocaleNumber(totalContent.total_stock),
        getLocaleNumber(totalContent.Consumption_Qty, 3),
        getLocaleNumber(totalContent.consumption_value),
        getLocaleNumber(totalContent.closing_stock),
        getLocaleNumber(totalContent.closing_value),
      ],
    );
    setPdfData({
      from: new Date(`${from}-01`),
      to: new Date(`${to}-01`),
      tableTitles: pdfTableTitles,
      tableContents: pdfTableContents,
    });
    setTableData(res);
    setItemData(res);
    hideLoader();
  };
  return (
    <>
      <SEO title="Store Item Bank Report" />
      <Card>
        <header>Store Item Bank Report</header>
        <CardBody>
          <Row>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                Month <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="month"
                  name="to"
                  value={to}
                  placeholder="Month"
                  onChange={(e) => setTo(e.target.value)}
                  className="to require"
                />
              </InputGroup>
            </Col>
          </Row>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Button
              status="Success"
              style={{
                marginRight: '10px',
              }}
              onClick={getApi}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <header>Store Item Bank Report</header>
        <CardBody>
          {tableData.length > 0 && (
            <p>
              <PdfGenerator documentTitle="store-item-bank-report" data={pdfData} />{' '}
              <CSVLink data={tableData}>Download Excel</CSVLink>
            </p>
          )}
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              onChange={(e) => filterChange(e.target.value)}
              placeholder="Filter"
              name="tableFilter"
              value={tableFilter}
            />
          </InputGroup>
          <DataTable
            columns={columns}
            data={tableData}
            fixedHeader
            allowOverflow={false}
            overflowY={false}
            overflowX={false}
            wrap={false}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ReceiptPOCReport;
